import React from 'react';
import { graphql, PageProps } from 'gatsby';

import Layout from '../layouts/Layout';
import SEO from '../components/SEO';
import WallWrapper from '../components/wall/WallWrapper';
import { Collections } from '../@types/GraphQL';

interface HomePageProps extends PageProps {
  data: {
    liveRecording: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.LiveRecording;
            slug: string;
          };
        };
      }[];
    };
    liveVideo: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.LiveVideo;
            slug: string;
          };
        };
      }[];
    };
    photo: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.Photo;
            slug: string;
          };
        };
      }[];
    };
    polaroid: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.Polaroid;
            slug: string;
          };
        };
      }[];
    };
    poster: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.Poster;
            slug: string;
          };
        };
      }[];
    };
    text: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.Text;
            slug: string;
          };
        };
      }[];
    };
    show: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.Show;
            slug: string;
          };
        };
      }[];
    };
  };
}

const HomePage: React.FC<HomePageProps> = function (props) {
  const { data } = props;

  return (
    <Layout currPage={props.location.pathname}>
      <SEO title="Courtney Barnett | LIVE" />
      <WallWrapper data={data} />
    </Layout>
  );
};

export default HomePage;

export const pageQuery = graphql`
  query pageQuery {
    liveRecording: allFile(
      filter: { sourceInstanceName: { eq: "live_recording" } }
    ) {
      ...LiveRecording
    }
    liveVideo: allFile(filter: { sourceInstanceName: { eq: "live_video" } }) {
      ...LiveVideo
    }
    photo: allFile(filter: { sourceInstanceName: { eq: "photo" } }) {
      ...Photo
    }
    polaroid: allFile(filter: { sourceInstanceName: { eq: "polaroid" } }) {
      ...Polaroid
    }
    poster: allFile(filter: { sourceInstanceName: { eq: "poster" } }) {
      ...Poster
    }
    text: allFile(filter: { sourceInstanceName: { eq: "text" } }) {
      ...Text
    }
    show: allFile(filter: { sourceInstanceName: { eq: "show" } }) {
      ...Show
    }
  }
`;
